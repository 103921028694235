<script setup lang="ts">
import { ref } from 'vue'
import { RouterView } from 'vue-router'
import { useRouter } from 'vue-router';

import Footer from '@/components/Footer.vue';
import ChangeTheme from "@/components/ChangeTheme.vue";
import GlobalSnackbar from '@/components/GlobalSnackbar.vue';
import Sidebar from '@/components/Sidebar.vue';

import useLoginManager from './services/loginManager';

import '@/styles/global.scss';

const loginManager = useLoginManager();
const router = useRouter();
const drawer = ref(true);
const isDev = import.meta.env.VITE_SENTRY_ENVIRONMENT == 'dev';

</script>

<style lang="scss" scoped>
.text-small {
  font-size: 70%;
}
</style>

<template>
  <template v-if="router.currentRoute.value.meta.fullscreen">
    <v-app>
      <v-main>
        <v-container fluid class="fill-height">
          <RouterView name="fullscreen" />
        </v-container>
      </v-main>
      <Footer></Footer>
    </v-app>
  </template>

  <template v-if="!router.currentRoute.value.meta.fullscreen && router.currentRoute.value.matched.length > 0">
    <v-app>
      <v-app-bar :color="isDev ? 'indigo' : ''">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          {{ $appName }}
          <v-icon v-if="isDev" title="DEV">mdi-bug</v-icon>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <ChangeTheme></ChangeTheme>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" elevation="2" width="220">
        <Sidebar></Sidebar>

        <!-- LOGOUT button -->
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block prepend-icon="mdi-logout" to="/logout" @click="loginManager.logout">
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main>
        <v-container>
          <RouterView></RouterView>
        </v-container>
      </v-main>

      <Footer></Footer>
    </v-app>
  </template>

  <GlobalSnackbar></GlobalSnackbar>
</template>
